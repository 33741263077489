import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
// import Sidebar from './Sidebar';
// import Chat from './Chat';
import Container from './Container';
import Loader from '../Partials/Loader';
import mainActions from '../Store/Actions/mainActions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Main({ isUserLoggedIn, init, isInit }) {

    useEffect(() => {
        if (isInit) {
            init();
        }
    });

    if (isInit) {
        return <Loader />
    }

    if (!isUserLoggedIn) {
        return <Redirect to="/auth/login" />
    }
    return <><Container />        
            <ToastContainer                
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={true}
                pauseOnVisibilityChange
                draggable
                pauseOnHover
            />
        
    </>
}

export default connect(state => ({
    isInit: state.main.isInit,
    isUserLoggedIn: state.main.isUserLoggedIn
}), dispatch => {
    return {
        init: () => {
            dispatch({ type: mainActions.INIT_REQUESTED, payload: {} });
        }
    }
})(Main);