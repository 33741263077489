import React from 'react';
import {connect} from 'react-redux';
function UserActiveAvatar({avatar,online_status}){
    let set_online_status = online_status === "online" ? "user-status online" : "user-status offline";
    return <div className="avatar">   
    {/* <img alt="" src="https://backoffice.durva.ir/profile/images/KMy8q4TGT9eoEJWw15MAz3SJ8C5EuO3mFDXfMTHH.jpeg.jpg" /> */}
    <img alt="" src={"https://backoffice.durva.ir/"+avatar} />
    <span className={set_online_status}></span>
</div>;
}


export default connect(state => ({   
}), dispatch => ({   
}))(UserActiveAvatar);