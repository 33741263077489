
export default {
    INIT_REQUESTED:"INIT_REQUESTED",
    INIT_SUCCESS:"INIT_SUCCESS",
    INIT_FAILED:"INIT_FAILED",   

    UPDATE_ONLINE_USER_INIT:"UPDATE_ONLINE_USER_INIT",
    UPDATE_ONLINE_USER_SUCCESS:"UPDATE_ONLINE_USER_SUCCESS",
    UPDATE_ONLINE_USER_FAILED:"UPDATE_ONLINE_USER_FAILED",

    SET_CONVERSATION_HEIGHT:"SET_CONVERSATION_HEIGHT"
};