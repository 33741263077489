import React from 'react';

import { getDateMoment } from '../../Utilitis/helper'

const ViewDiseaseCategory = ({ result, question_answers }) => {

    const response = <div className="bg-dark rounded shadow text-white text-justify p-2">
        <strong>نتیجه‌: </strong>
        <div className="d-flex">
            <div className="w-50"><strong>وضعیت : </strong> &nbsp; {result.answer ? <span className="text-success">پاسخ داده شده </span> : <span className="text-danger">پاسخ داده نشده </span>}</div>
            <div className="w-500"><strong>تاریخ ایجاد : </strong> &nbsp;<span className="date">{getDateMoment(result.date)}</span></div>
        </div>
        <p ><strong>توضیحات کارشناس : </strong>&nbsp; {result.doctor_description}</p>
    </div>;

    return <div>
        {response}
        {question_answers.map((question_answer, index) => {
            let question_type = question_answer.question_type;
            return <div key={"question_answer" + index}>
                <div><strong className="d-inline-block p-1">{index + 1}- </strong>
                    {question_answer.question} <br></br>
                    <div className="px-2 my-1 border border-secondary rounded shadow-sm">
                        <strong>جواب :</strong>
                        {question_answer.options.map((option, key) => {
                            let answer = "";
                            let description = "";
                            if (question_type === "boolean" || question_type === "radio_button") {
                                answer = question_answer.user_answer === option.id ? 'icon-tick text-success px-2' : 'icon-cross px-2 text-danger';
                                description = question_answer.description;
                            }

                            if (question_type === "checkbox") {
                                for (let i = 0; i < question_answer.user_answer.length; i++) {
                                    if (question_answer.user_answer[i] === option.id) {
                                        answer = 'icon-tick text-success px-2';
                                        description = question_answer.description[i] ? question_answer.description[i] : '';
                                        break;
                                    } else {
                                        answer = 'icon-cross px-2 text-danger';
                                    }
                                }
                            }
                            return <div key={"option" + key}>
                                <strong className="d-inline-block p-1"><span className={answer}></span> <span>{key + 1}</span>- </strong>
                                {question_answer.question_type === "boolean" ? option.label : option.question}
                                <p className="mb-1">{description}</p>
                            </div>
                        })}
                        {question_type === "text" ? <p><strong>توضیحات : </strong>{question_answer.description}</p> : null}
                    </div>
                </div>
            </div>
        })}
    </div>
}

export default ViewDiseaseCategory;