import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

function Avatar({ type, user, admins, message }) {    
    if (type === "admin") {
        if (message.admin_id) {
            let adminIndex = _.findIndex(admins, function (admin) { return admin.id === message.admin_id; });
            if (admins[adminIndex]) {
                let admin = admins[adminIndex];
                return (<div className="admin-info">
                    <img alt="" src={admin.avatar} />
                    <span className="fullname">{admin.name}</span>
                </div>);
            }
        }
        return (<div>
            <img alt="" src="https://durva.ir/profile/durva2.png" />
        </div>);
    }

    
    return (<div><img alt="" src={'/' + user.avatar} onError={(e) => {
        e.target.onerror = null;
        e.target.src = "https://durva.ir/profile/images/avatar.jpg";
    }} /></div>);


}


export default connect(state => ({
    admins: state.main.admins,
    user: state.main.activeUser,
}), dispatch => ({

}))(Avatar);


// return (<div><img alt="" src={'http://localhost/' + user.avatar} onError={(e) => {
//     e.target.onerror = null;
//     e.target.src = "https://durva.ir/profile/images/avatar.jpg";
// }} /></div>);