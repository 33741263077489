import webSocketio from 'socket.io-client';
class SocketService {
    constructor() {
        this.token = localStorage.getItem('token')
        this.type = 'admin';
        this.socket = null;
        // this.base_url = "http://localhost/socket.io"
        this.base_url = "https://durva.ir/"

        // this.base_url = "https://durva.ir"
        // this.base_url = "https://durva.ir"
        // this.base_url = "http://localhost:3003"
    }

    connect(callback) {
        try {
            // socket = io('https://durva.ir', { path: '/websocket', query: 'token=' + token + '&user_type=admin' });
            if (this.token && !this.socket) {
                // this.token='602610f05a257227d65211d963f2ed7adbe93271cf17d8be4606ee35e87315bc';
                // this.type='user';
                console.log("connect method :step ")
                this.socket = webSocketio(this.base_url, {
                    // path: '/websocket',
                    query: 'token=' + this.token + '&user_type=' + this.type,
                });
                this.socket.on("connect", () => {                   
                    console.log("socket.io.engine.id connect:  step ", this.socket.io.engine.id)
                    callback(this.socket)
                });
                this.socket.on("disconnect", () => {
                    console.log("disconnect :step ", this.socket.io.engine.id);
                    this.socket = null;
                    callback(null);
                })
            } else {
                console.error("else :connect: step ");
                callback(null);
            }
        } catch (error) {
            callback(null);
            console.error("SocketService:connect: step ", error);
        }
    }

    disconnect() {
        if (this.socket) {
            console.log("socketDisConnect socket.io.engine.id", this.socket.io.engine.id);
            this.socket.disconnect();
            this.socket.removeAllListeners();
            this.socket = null;
        }
    }


}

export default SocketService;