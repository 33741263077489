import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

function ParentMessage({activeUser,userMessages,admins,parent_id}){
    let message = _.findIndex(userMessages, function (message) { return message.id === parent_id; });
      
    if (message >= 0) {
        let message_object = userMessages[message];
        let userReplay = {
            id: 0,
            name: '',
            lastname: '',
            chat_id: 0
        };
        if (message_object.sender === "user") {
            let user = activeUser;
            userReplay.id = user.id;
            userReplay.name = user.name;
            userReplay.lastname = "";
            userReplay.chat_id = parent_id;

        } else {
            let admin = null;
            let adminIndex = _.findIndex(admins, function (admin) { return admin.id === message_object.admin_id; });
            if (adminIndex >= 0) {
                admin = admins[adminIndex];
                userReplay.id = admin.id;
                userReplay.name = admin.name;
                userReplay.lastname = admin.lastname;
                userReplay.chat_id = parent_id;
            }
        }
        return (
            <div className="parent-info">
                <div>
                    {
                        message_object.content.text ?
                            <div>
                                <div>{  userReplay.id +" "+userReplay.name + ' ' + userReplay.lastname}</div>
                                <div> {message_object.content.text.substring(0, 50)}</div>
                            </div>
                            : null
                    }
                </div>
                <div>{message_object.content.url ? <img alt="" src={message_object.content.url} /> : null}</div>
            </div>
        );
    }
    return (<div>{parent_id}</div>)
}



export default connect(state => ({
    userMessages: state.main.userMessages,
    admins: state.main.admins,
    activeUser: state.main.activeUser,

}), dispatch => ({

}))(ParentMessage);
