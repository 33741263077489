import React from 'react';
import { connect } from 'react-redux';
import userAction from './../../Store/Actions/userActions'

function Header({ tab,unread_message, setActiveTabUsers }) {

    const checkActiveTab = (tabSelected) => {
        if (tabSelected === "all" && tab !== "all") {
            setActiveTabUsers('all')
        } else if (tabSelected === "unread" && tab !== "unread") {
            setActiveTabUsers('unread')
        } else if (tabSelected === "read" && tab !== "read") {
            setActiveTabUsers('read')
        }
    }

    return (<ul className="aside-header">
        <li><a href="# " className={tab === "all" ? "active" : ""} onClick={e => checkActiveTab('all')}>همه</a></li>
        <li><a href="# " className={tab === "unread" ? "active" : ""} onClick={e => checkActiveTab('unread')}>خوانده نشده <span className="text-danger">({unread_message})</span></a></li>
        <li><a href="# " className={tab === "read" ? "active" : ""} onClick={e => checkActiveTab('read')}>پاسخ داده شده </a></li>
    </ul>);
}


export default connect(state => ({
    tab: state.user.tab,
    unread_message:state.user.unread_message
}), dispatch => ({
    setActiveTabUsers: (tab) => dispatch({ type: userAction.USER_ACTIVE_TAB_REQUEST, payload: { tab: tab } })
}))(Header);