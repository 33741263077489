import React, { useEffect } from 'react';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import ViewCycle from './ViewCycle';

function CycleInfo({ activeUserInfo, cycleInfo, cycles }) {

   
    useEffect(() => {
       

    }, [activeUserInfo])
    const viewCycles = (userCycles) => {
        console.log(" cycles : ", cycles);
        console.log("cycles mount: userCycles ", userCycles)
        Swal.fire({
            title:'مشخصات سیکل های کاربر ',
            html: renderToString(<ViewCycle durva={activeUserInfo.durva_cycle} cycles={userCycles} />),
            customClass: 'swal-wide mt-3 user-cycles',
            confirmButtonColor: '#dc3545',
            confirmButtonText: 'بستن',
        });
    }

    if (!cycleInfo) {
        return (<div />);
    }

    return <div>
        <ul className="list-group">
            <li className="list-group-item py-1"><label> طول سایکل : </label> <span>{cycleInfo.cycle_length}</span></li>
            <li className="list-group-item py-1"><label> طول پریود : </label> <span>{cycleInfo.period_length}</span></li>
            <li className="list-group-item py-1"><label> طول (PMS) : </label> <span>{cycleInfo.pms_length}</span></li>
            <li className="list-group-item p-0">
                <button onClick={() => viewCycles(cycles)} className="btn btn-primary btn-block">مشاهده سیکل جاری </button>
            </li>
        </ul>
    </div>;
}


export default connect(state => ({
    activeUserInfo: state.main.activeUserInfo,
    cycleInfo: state.main.cycleInfo,
    cycles: state.main.cycles
}), dispatch => ({

}))(CycleInfo);

