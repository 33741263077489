import React, { useEffect,useState } from 'react';
import { connect } from 'react-redux';
import userActions from '../Store/Actions/userActions';
import Sidebar from './Sidebar/Sidebar';
import Chat from './Chat/Chat';
import ChatInfo from './Chat/ChatInfo';
import Compose from './Chat/Compose';
import SplashScreen from './Partials/SplashScreen';

const NAVIGATION = 78; 
const SIDEBAR = 365; 
const PROFILE = 270; 

function Dashboard({ currentChat,activeUser, initUserList ,init_request}) {
        
    const [width] = useState((( window.innerWidth - NAVIGATION  ) -SIDEBAR ) - PROFILE );

    useEffect(() => {       
        if(!init_request){      
            initUserList({offset:0});   
        }    
    });

    const currentScreen = currentChat ? <><ChatInfo /><div className="wrapper-chat" style={{ width:width+'px' }}><Chat maxWidth={width} /><Compose maxWidth={width} /></div> </> : <SplashScreen />;
    
    return (
        <div className="main-container">
            <>
                {currentScreen}
                <Sidebar />
            </>
        </div>
    );
};


export default connect(state => ({
    currentChat: state.main.currentChat,   
    activeUser: state.main.activeUser, 
    socketConnection: state.socket.socketConnection,
    socketStatus: state.socket.socketStatus,
    socketMessage: state.socket.socketMessage,
    init_request:state.user.init_request,
  
}), dispatch => ({
    initUserList: payload =>{       
        dispatch({ type: userActions.USER_LIST_REQUEST, payload: payload })
    } ,
}))(Dashboard);
