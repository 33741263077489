import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { setLink } from '../../Utilitis/helper';


function TextLog({ content }) {
    let text = content && content.text ? setLink(content.text) : "";
    return <div>{text.split('\n').map((it, i) => <div key={'x' + i}>{ReactHtmlParser(it)}</div>)}</div>;
}

export default TextLog ;