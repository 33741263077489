import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import UserActiveAvatar from './UserActiveAvatar';
import UserActiveData from './UserActiveData';
import UserActiveLabels from './UserActiveLabels';
import CycleInfo from './CycleInfo';
import DiseaseCategories from './DiseaseCategories';


function ChatInfo({ activeUser,subscriptions ,userActiveOnline}) {

    console.log("activeUseractiveUser: ",activeUser.active_chat_code)
    const [activeTab, setActiveTab] = useState('userLabel');

    useEffect(() => {

        return () => {
            // setActiveTab('userLabel');
        };        
    },[activeTab,activeUser,userActiveOnline])
   
    let subscriptionsInfo  = subscriptions && subscriptions.is_active? <span className="text-success"> فعال </span> : <span className="text-danger">غیر فعال</span> ;
    let activeChat = activeUser.active_chat_code? <span className="text-success"> فعال </span> : <span className="text-danger">غیر فعال</span> ;
    return (<div className="chat-info">
        <header>مشخصات کاربر </header>
        <div className="info">
            <UserActiveAvatar avatar={activeUser.avatar} online_status={activeUser.online_status} />
            <UserActiveData user={activeUser} />           
        </div>
        <div><label>وضعیت مشاوره : </label> {activeChat} </div>
        <div><label>وضعیت اشتراک : </label> {subscriptionsInfo} </div>
        <div className="accordion">
            <div className="card">
                <div className="card-header text-white bg-secondary py-1"
                    onClick={(e) => setActiveTab('cycle')}>مشخصات سایکل </div>
                <div className={activeTab === 'cycle' ? 'collapse show' : 'collapse'}>
                    <div className="card-body p-1">
                        <CycleInfo />
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-white bg-dark py-1"
                    onClick={(e) => setActiveTab('userLabel')}>برچسب ها  </div>
                <div className={activeTab === 'userLabel' ? 'collapse show' : 'collapse'}>
                    <div className="card-body p-1">
                        <UserActiveLabels />
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header text-white bg-primary py-1"
                    onClick={(e) => setActiveTab('diseaseCategories')}>ارزیابی بیماری  </div>
                <div className={activeTab === 'diseaseCategories' ? 'collapse show' : 'collapse'}>
                    <div className="card-body p-0">
                        <DiseaseCategories />
                    </div>
                </div>
            </div>
        </div>
    </div>);
}

export default connect(state => ({
    activeUser: state.main.activeUser,
    subscriptions: state.main.subscriptions,
    userActiveOnline:state.main.userActiveOnline
}), dispatch => ({

}))(ChatInfo);

