import {all} from 'redux-saga/effects';

import * as authHandler from './auth';
import * as mainHandler from './main';
import * as socketHandler from './socket';
import * as userHandler from './user';
import * as userActiveHandler from './activeUser';
import * as messagesHandler from './message';



export default function* root(){
    yield all([        
        socketHandler.socketConnectWatcher(),
        socketHandler.socketDisConnectWatcher(),
        
        authHandler.userLoginWatcher(),
        authHandler.userLogoutWatcher(),
        authHandler.authCheckWatcher(),
        
        mainHandler.initWatcher(),
        
        userHandler.userWatcher(),
        userHandler.searchUserWatcher(),
        userHandler.setTabUserWatcher(),

        userActiveHandler.userActiveWatcher(),
        userActiveHandler.userActiveMessageWatcher(),
        userActiveHandler.userActiveInfoWatcher(),
        userActiveHandler.addLabelWatcher(),
        userActiveHandler.deleteLabelWatcher(),
        
        messagesHandler.messagesWatcher(),
    ]);
}