import React from 'react';
import { convertDateTime } from '../../Utilitis/helper';

function MsgInfo({ message }) {

    let shtmlStatus = '';
    let dateHtml = '';
    if (message.status === "seen" || message.status === 2) {
        shtmlStatus = <span className="seen">
            <span className="icon-tick-3"></span>
            <span className="icon-tick-3"></span>
        </span>;
    } else if (message.status === "deliver" || message.status === 1) {
        shtmlStatus = <span className="deliver">
            <span className="icon-tick-3"></span>
            <span className="icon-tick-3"></span>
        </span>;
    } else {
        shtmlStatus = <span className="send"><span className="icon-tick-3">{message.status}</span></span>;
    }


    if (message.sender === "user") {
        dateHtml = <span className="chat-send-date">
            <span className="date-time">{convertDateTime(message.received_date)} </span>
        </span>
    } else {
        dateHtml = <span className="chat-recive-date">{message.send_date ? <span className="date-time"> {convertDateTime(message.send_date)} </span> : null} </span>;
    }
    return <div className="msg-info">
        {shtmlStatus}
        {dateHtml}
    </div>
}

export default MsgInfo;