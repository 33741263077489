import React from 'react';
import { getDateMoment } from '../../Utilitis/helper'

import PropTypes from 'prop-types';

const ViewCycle = ({ durva, cycles }) => {
    if (durva) {
        return <div className="">این کاربر از  اپلیکیشن نسخه ورژن یک استفاده می کند.</div>;
    }
    return <div className="table-responsive">
        <table className="table table-bordered table-striped table-sm" style={{ whiteSpace: 'nowrap' }}>
            <thead>
                <tr>
                    <th scope="col">ردیف</th>
                    <th scope="col">نوع سیکل</th>
                    <th scope="col">وضعیت </th>
                    <th scope="col">طول سیکل</th>
                    <th scope="col">شروع پریود</th>
                    <th scope="col">پایان پریود</th>
                    <th scope="col">طول پریود</th>
                    <th scope="col">شروع باروری</th>
                    <th scope="col">پایان باروری</th>
                    <th scope="col"> طول باروری </th>
                    <th scope="col">تخمک گذاری</th>
                    <th scope="col">شروع پیش قاعدگی</th>
                    <th scope="col">پایان پیش قاعدگی</th>
                    <th scope="col">طول پیش قاعدگی</th>
                </tr>
            </thead>
            <tbody>
                {
                    cycles.map((cycle, index) => {
                        return (
                            <tr key={"cycle" + index}>
                                <th scope="row">{index + 1}</th>
                                <td>{cycle.cycle_status_id === 1 ? <span className="text-success">دروا</span> : <span className="text-primary">سیکل</span>}</td>
                                <td>{cycle.is_regular === 0 ? <span className="text-danger">نامنظم</span> : <span className="text-success">منظم</span>}</td>
                                <td>{cycle.cycle_length}</td>
                                <td><span className="date">{getDateMoment(cycle.period_start_at)}</span></td>
                                <td><span className="date">{getDateMoment(cycle.period_end_at)}</span></td>
                                <td>{cycle.period_length}</td>
                                <td><span className="date">{getDateMoment(cycle.fertility_start_at)}</span></td>
                                <td><span className="date">{getDateMoment(cycle.fertility_end_at)}</span></td>
                                <td>{cycle.fertility_length}</td>
                                <td><span className="date">{getDateMoment(cycle.ovulation_date)}</span></td>
                                <td><span className="date">{getDateMoment(cycle.pms_start_at)}</span></td>
                                <td><span className="date">{getDateMoment(cycle.pms_end_ad)}</span></td>
                                <td>{cycle.pms_length}</td>
                            </tr>
                        );
                    })
                }
            </tbody>
        </table>
    </div>;
}

ViewCycle.propTypes = {
    durva: PropTypes.number,
    cycles: PropTypes.array,
};

export default ViewCycle;