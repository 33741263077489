import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { convertDateTime } from './../../Utilitis/helper';
import { socket } from '../Container';
import userActions from '../../Store/Actions/userActions';
import { orderBy } from 'lodash'
const styleRect = { width: '1.2rem', height: '1.2rem' };
function UsersLists({ users, users_flag, userResetCounter,active, next_offset, setActiveUser }) {
    const [activeId, setActiveId] = useState(0);
    const selectedUser = (user) => {
        setActiveId(user.id);
        setActiveUser(user);
        if (user.count_message) {
            socket.emit("admin:seen:active:user", user.id);
            userResetCounter(user.id)
        }
    }
    
    useEffect(() => {    
    }, [users, users_flag])

    let updateCounterusers = orderBy(users, ['message_status','sort'], ['asc','desc']);
    return (<ul className="users-container" >
        {
            updateCounterusers.map((user, index) => {
                let user_status = 'badge badge-success icon-tick p-1';
                if (user.message_status === 0) {
                    user_status = 'badge badge-primary p-1';
                } else if (user.message_status === 1) {
                    user_status = 'badge badge-danger';
                }
                return <li className={user.id === activeId ? 'user-item active' : 'user-item'} key={index} onClick={(e) => selectedUser(user)}>
                    <div className="user-data">
                        <div className="user-info">
                            <div className="user-id">{user.id}</div>
                            <div className="fullname" >{user.name}</div>
                            <div className="created_at"> {convertDateTime(user.created_at)}</div>
                        </div>
                        <p>{user.content ? user.content.text.substring(0, 80) : null} </p>                        
                    </div>
                    <div>

                        {
                            user.count_message && parseInt(user.message_status) !== 2 ?
                                <span style={styleRect} className="badge badge-primary p-1">{user.count_message}</span> :
                                <span style={styleRect} className={user_status}> </span>
                        }
                    </div>
                    <div className="avatar">
                        <img alt=" " src={'https://durva.ir/'+user.avatar}
                        />
                        <span className={user.online_status === "online" ? 'user-status online' : 'user-status offline'}>&nbsp;</span>
                    </div>
                </li>
            })
        }
    </ul>);
}



export default connect(state => ({
    users: state.user.users,
    next_offset: state.user.next_offset,
    users_flag: state.user.users_flag
}), dispatch => ({
    setActiveUser: user => {
        dispatch({ type: userActions.ACTIVE_USER_INFO_REQUEST, payload: { userId: user.id } });       
        dispatch({ type: userActions.ACTIVE_USER_MESSAGES, payload: { init: true, user: user, offset: 0 } });
    },
    userResetCounter:userId => dispatch({ type: userActions.ACTIVE_USER_RESET_COUNTER, payload: { userId: userId } })

}))(UsersLists);

