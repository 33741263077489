import {takeLatest,put,call} from 'redux-saga/effects';
import mainActions from '../Actions/mainActions';
import HttpService from '../../Services/HttpService';

const httpService = new HttpService();


const initWorker = function* (action){
    try{
        const result = yield call(()=>{
            const token= localStorage.getItem('token');       
            return httpService.post('/auth/init',action.payload,{
                headers:{
                    'Authorization': `Bearer ${token}`,   
                    // 'Authorization': `Bearer fsdfsdfsdfsd`,                    
                }
            });
            // .then(response=>response.body).catch(error=>{});
        });
        
        yield put({type:mainActions.INIT_SUCCESS,payload:result.data}) ;       
        // registerUser
    }catch(error){
        console.error("initWorker result ",error)
        yield put({type:mainActions.INIT_FAILED,payload:error.response.data}); 
    }
};

export const initWatcher = function* (){
    yield takeLatest(mainActions.INIT_REQUESTED,initWorker)
};
