import {combineReducers} from 'redux';

import main from './main';
import socket from './socket';
import user from './user';





export default combineReducers({
    // users,
    main,
    socket,
    user
});