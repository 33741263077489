import React, { Component } from 'react'

export default class NotFound extends Component {
    render() {
        return (
            <div className="not-found">
                <h2>NotFound...</h2>
            </div>
        )
    }
}
