import React, { PureComponent } from 'react'
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import webSocketio from 'socket.io-client';

import SocketContext from '../Contexts/SocketContext';
import SocketService from '../Services/SocketService';
import Dashboard from './Dashboard';
import Navigation from './Navigation/Navigation';
import socketActions from '../Store/Actions/socketActions';
import chatActions from '../Store/Actions/chatActions';
import userActions from '../Store/Actions/userActions';

var socket = null;
class Container extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            socket: props.socketConnection,
            socketStatus: props.socketStatus,
            socketMessage: props.socketMessage,
            show_toast: true,
            show_toast_flag: true
        }
        this.socketService = new SocketService();
        this.token = localStorage.getItem('token');
        this.base_url = "https://durva.ir/socket.io";
        // this.base_url = "http://localhost:3000/";

        this.type = 'admin';
    }

    componentDidMount() {
        this.connect();   
    }
    componentDidCatch(error, info) {
        console.log("error, info Container:componentDidCatch: ", error, info);
    }


    buildContextData(me) {
        if (me) {
            // this.socketService = new SocketService();
            // this.socketService.connect();
            // this.clientService.setCurrentClient(me);
            // this.socket.setClient(this.clientService);
            // this.socket.connect();
            // this.peerService.setCleint(this.clientService);
            return {
                // socketService: this.socketService.socket  ,
                // peerService:this.peerService.init()
                // eventManager:new EventManager()
            }
        }
        return {};
    }
    connect = () => {
        socket = webSocketio(this.base_url, {
            // path: '/socket.io',
            query: 'token=' + this.token + '&user_type=' + this.type,
        });
        socket.on("connect", () => {
            // console.log("socket.io.engine.id connect:  step ", socket.io.engine.id)
            this.props.socketConnect(socket);
        });
        socket.on('connect_error', (error) => {
            console.error("connect_error err: step ", this.state.show_toast, error.message);
            if (this.state.show_toast) {
                this.setState({ show_toast: false })
                toast.error("کاربر گرامی  ارتباط شما با سرور قطع می باشد.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    autoClose: false,
                    closeOnClick: true,
                    draggable: false
                });
            }
        });
        socket.on('error', (error) => {
            console.error("on error err: step ", error);
        });
        socket.on('connect_failed', err => {
            console.error("connect_failed err step:", err);
        });

        socket.on("reconnect", () => {
            console.log('reconnect Connection reconnect step : ', socket.io.engine.id);
            // socket.removeAllListeners();
            this.setState({ show_toast: true })
            toast.dismiss();
            toast.success("کاربر گرامی  ارتباط شما با سرور برقرار شد.", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 5000,
                closeOnClick: true,
                draggable: false
            });
        });

        socket.on("admin:user:online", (userId) => {
            this.props.setUserOnline(userId);
            const { activeUser } = this.props;
            userId = parseInt(userId);
            if (activeUser && parseInt(activeUser.id) === userId) {
                this.props.setActiveUserOnline(userId);
            }
        });

        socket.on("admin:user:offline", (userId) => {
            this.props.setUserOffline(userId);
            const { activeUser } = this.props;
            userId = parseInt(userId);
            if (activeUser && parseInt(activeUser.id) === userId) {
                this.props.setActiveUserOffline(userId);
            }
        });

        socket.on("admin:users:online:count", (count) => {
            this.props.updateUserOnlineCount(count);
        });

        socket.on("admin:total:message_unread", (message_unread) => {
            console.log("message_unread : ", message_unread);
            this.props.setUnreadMessage(message_unread);
        });
        socket.on("admin:deliver:message", (message) => {
            this.props.adminReplacePushMessage(message)
        });
        socket.on("client_to_admin_deliver_message", (message) => {
            this.props.adminDeliveredMessage(message)
        });

        socket.on("user:seen:message", (param) => {
            const { activeUser } = this.props;
            if (activeUser && param.user_id === activeUser.id) {
                this.props.userSeenMessage(param)
            }
        })

        // IoManager.to(socketId).emit("admin:user:status", { userId: user.info.id });
        // ENUM('close', 'wait', 'open')

        socket.on("admin:user:status", (userId) => {
            let user_id = parseInt(userId);
            const { activeUser } = this.props;
            this.props.setUserStatus(userId);
            if (activeUser && parseInt(activeUser.id) === user_id) {
                this.props.setActiveUserStatus(user_id);
                console.log("setActiveUserStatus : ", user_id);
            }
        });

        socket.on("recive-message-new-from-user", (message) => {
            let findUser = this.getFindUser(message.user_id);
            if (findUser) {
                const { activeUser } = this.props;
                let messageId = parseInt(message.id);
                let user_id = parseInt(message.user_id);
                if (activeUser && parseInt(activeUser.id) === user_id) {
                    socket.emit("admin:seen:message", { user_id: message.user_id, message_max_id: messageId });
                    this.props.pushNewMessage(message);
                } else {
                    this.props.receivedNewMessage(message);
                }
            } else {
                socket.emit("admin:get:user", message.user_id);
            }
        });

        socket.on("admin:push:user", (user) => {
            this.props.pushNewUser(user);
        });

        socket.on("admin:end:conversation:response",(message)=>{
            console.log("admin:end:conversation:response: ",message);
            this.props.endConversationUser(message);
        });

    }
    getFindUser = (userId) => {
        const { users } = this.props;
        let user = null;
        for (let i = 0; i < users.length; i++) {
            if (parseInt(users[i].id) === parseInt(userId)) {
                user = users[i];
                break;
            }
        }
        return user;
    }
    disConnect = () => {
        this.socketService.disconnect();
        this.props.socketDisConnect();
    }

    componentWillUnmount() {
        this.socketService.disconnect();
        this.props.socketDisConnect();
    }
    componentDidUpdate(prevProps, prevState, ) {
        // console.log("componentDidUpdate step")
    }
    static getDerivedStateFromProps(props, state) {

        if (state.socketStatus !== props.socketStatus) {
            return {
                socket: props.socketConnection,
                socketStatus: props.socketStatus,
                socketMessage: props.socketMessage
            }
        }
        return null;
    }
    render() {
        return (<div className="layout">
            <SocketContext.Provider value={this.buildContextData(this.props.me)}>
                <Dashboard />
                <Navigation />
            </SocketContext.Provider>
        </div >)
    }
}


export default connect(state => ({
    me: state.main.me,
    socketConnection: state.socket.socketConnection,
    socketStatus: state.socket.socketStatus,
    socketMessage: state.socket.socketMessage,
    users: state.user.users,
    activeUser: state.main.activeUser,
}), dispatch => {
    return {
        socketConnect: (socket) => dispatch({ type: socketActions.SOCKET_CONNECT, payload: { socket: socket } }),
        socketDisConnect: () => dispatch({ type: socketActions.SOCKET_DISCONNECT, payload: {} }),

        updateUserOnlineCount: (count) => dispatch({ type: userActions.UPDATE_ONLINE_USER_COUNT, payload: count }),
        setUserOnline: (userId) => dispatch({ type: userActions.ONLINE_USER, payload: userId }),
        setUserOffline: (userId) => dispatch({ type: userActions.OFFLINE_USER, payload: userId }),

        setActiveUserOnline: (userId) => dispatch({ type: chatActions.ONLINE_ACTIVE_USER, payload: userId }),
        setActiveUserOffline: (userId) => dispatch({ type: chatActions.OFFLINE_ACTIVE_USER, payload: userId }),

        setActiveUserStatus: (userId) => dispatch({ type: userActions.ACTIVE_USER_STATUS, payload: userId }),
        setUserStatus: (userId) => dispatch({ type: userActions.USER_STATUS, payload: userId }),



        setUnreadMessage: (counter) => dispatch({ type: userActions.UPDATE_UNREAD_MESSAGE, payload: counter }),
        adminDeliveredMessage: (obj) => dispatch({ type: chatActions.DELIVERED_MESSAGE, payload: obj }),
        userSeenMessage: (obj) => dispatch({ type: chatActions.USER_SEEN_MESSAGE, payload: obj }),
        adminReplacePushMessage: (message) => dispatch({ type: chatActions.REPLACE_PUSH_MESSAGE, payload: message }),
        receivedNewMessage: (message) => dispatch({ type: userActions.RECEIVED_NEW_MESSAGE, payload: message }),
        pushNewMessage: (message) => dispatch({ type: chatActions.PUSH_NEW_MESSAGE, payload: message }),
        pushNewUser: (user) => dispatch({ type: userActions.PUSH_NEW_USER, payload: user }),

        endConversationUser:(message)=>dispatch({ type: chatActions.END_CONVERSATION_USER, payload: message }),
    };
})(Container);

export { socket };




