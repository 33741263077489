import { takeLatest, put, call } from 'redux-saga/effects';
import authActions from '../Actions/authActions';
import HttpService from '../../Services/HttpService';

const httpService = new HttpService();

// const userRegisterWorker = function* (action){
//     try{
//         const result = yield call(()=>{
//             return httpService.post('auth/register',action.payload);
//             // .then(response=>response.body).catch(error=>{});
//         });
//         yield put({type:userActions.USER_REGISTER_SUCCESS,payload:result.data});
//         // registerUser
//     }catch(error){
//         yield put({type:userActions.USER_REGISTER_FAILED,payload:error.response.data}); 
//     }
// };

// export const userRegisterWatcher = function* (){
//     yield takeLatest(userActions.USER_REGISTER,userRegisterWorker)
// };


const userLoginWorker = function* (action) {
    try {
        const result = yield call(() => {
            return httpService.post('auth/login', action.payload);
            // .then(response=>response.body).catch(error=>{});
        });
        console.log("userLoginWorker result ", result.data);
        yield call(() => {
            localStorage.setItem('token', result.data.token);
        });
        yield put({ type: authActions.USER_LOGIN_SUCCESS, payload: result.data });        
        
    } catch (error) {       
        yield call(() => {
            localStorage.removeItem('token');
        });
        yield put({ type: authActions.USER_LOGIN_FAILED, payload: error.response.data });
    }
};

export const userLoginWatcher = function* () {
    yield takeLatest(authActions.USER_LOGIN, userLoginWorker)
};



export const userLogoutWorker = function* () {
    try{        
        yield call(() => {
            localStorage.removeItem('token');
        });
        yield put({ type: authActions.USER_LOGOUT_SUCCESS, payload: {} });
    }catch(error){
        console.log("USER_LOGOUT_FAILED ",error);       
        yield put({ type: authActions.USER_LOGOUT_FAILED, payload: {} });
    }
}

export const userLogoutWatcher = function* () {
    yield takeLatest(authActions.USER_LOGOUT, userLogoutWorker)
}


export const authCheckWorker = function* (action) {
    try{ 
        console.log("action : ",action);
        const result = yield call(() => {
             const token= localStorage.getItem('token');       
            return httpService.get('/auth/check',{
                headers:{
                    'Authorization': `Bearer ${token}`,   
                    // 'Authorization': `Bearer fsdfsdfsdfsd`,                    
                }
            });
        });
        console.log("action result: ",result);
        yield put({ type: authActions.AUTH_CHECK_SUCCESS, payload: {} });
    }catch(error){
        console.log("USER_LOGOUT_FAILED ",error);       
        yield put({ type: authActions.AUTH_CHECK_FAILED, payload: {} });
    }
}

export const authCheckWatcher = function* () {
    yield takeLatest(authActions.AUTH_CHECK, authCheckWorker)
}

