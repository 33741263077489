import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import authActions from '../../Store/Actions/authActions';
// import SocketContext from '../../Contexts/SocketContext';
// import { socket } from '../Container';

function Navigation({ logout, user_online_count }) {




    useEffect(() => {

    });

    // function disConnect() {

    // }

    // function connect() {

    // }


    return (<div className="navigation">
        <ul>
            <li className="p-0">
                 <a href="# " className="d-flex justify-content-around text-success pt-2">
                    <span className="badge badge-success py-1">{user_online_count}</span>
                    <span className=" mt-1" style={{ fontSize:'.7rem' }}> آنلاین </span>
                </a>
            </li>
            <li> <a href="# " className="d-block text-danger py-2 mb-1 text-center" onClick={(e) => logout()}>خروج</a></li>
            
        </ul>
    </div>)
}


export default connect(state => ({
    socketConnection: state.socket.socketConnection,
    user_online_count: state.user.user_online_count
}), dispatch => ({
    logout: () => dispatch({ type: authActions.USER_LOGOUT, payload: {} })
}))(Navigation);


{/* <li> <a href="# " className="disconnect" onClick={(e) => disConnect()}>قطع</a></li>
            <li> <a href="# " className="connect" onClick={(e) => connect()}>وصل</a></li> */}