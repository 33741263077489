import { takeLatest, put, call } from "redux-saga/effects";
import { serialize } from "../../Utilitis/helper";
import userActions from "../Actions/userActions";
import HttpService from "../../Services/HttpService";

const httpService = new HttpService();

export const userActiveWorker = function* (action) {
  try {
    const result = yield call(() => {
      const token = localStorage.getItem("token");
      let offset = { offset: action.payload.offset }
      return httpService.get("chat/" + action.payload.user.id + "/user" + serialize(offset), {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    });   
    yield put({ type: userActions.ACTIVE_USER_SUCCESS, payload: result.data });
  } catch (error) {
    console.error("error userWorker: ", error.response.data)
    yield put({
      type: userActions.ACTIVE_USER_FAILED,
      payload: error.response.data
    });
  }
};

export const userActiveWatcher = function* () {
  yield takeLatest(userActions.ACTIVE_USER_MESSAGES, userActiveWorker);
};

export const userActiveMessageWatcher = function* () {
  yield takeLatest(userActions.MESSAGE_LOAD_MORE_REQUEST, userActiveWorker);
};



///////////////// USER ACTIVE GET INFO 
export const userActiveInfoWorker = function* (action) {
  try {
    const result = yield call(() => {
      const token = localStorage.getItem("token");
      return httpService.get("active-user/" + action.payload.userId + "/info", {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    });
    
    yield put({ type: userActions.ACTIVE_USER_INFO_SUCCESS, payload: result.data });
  } catch (error) {
    console.error("error userActiveInfoWorker: ", error.response.data)
    yield put({
      type: userActions.ACTIVE_USER_INFO_FAILED,
      payload: error.response.data
    });
  }
}

export const userActiveInfoWatcher = function* () {
  yield takeLatest(userActions.ACTIVE_USER_INFO_REQUEST, userActiveInfoWorker);
};


///ACTIVE_USER_ADD_LABEL


export const addLabelWorker = function* (action) {
  try {
    const result = yield call(() => {
      const token = localStorage.getItem("token");
      return httpService.post("active-user/add-label", action.payload, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    });   
    yield put({ type: userActions.ACTIVE_USER_LABEL_PUSH_SUCCESS, payload: result.data });
  } catch (error) {
    console.error("error userActiveInfoWorker: ", error.response.data)
    yield put({
      type: userActions.ACTIVE_USER_LABEL_PUSH_FAILED,
      payload: error.response.data
    });
  }
}

export const addLabelWatcher = function* () {
  yield takeLatest(userActions.ACTIVE_USER_ADD_LABEL, addLabelWorker);
};



export const deleteLabelWorker = function* (action) {
  try {
    const result = yield call(() => {
      const token = localStorage.getItem("token");
      const { id } = action.payload;
      return httpService.delete("active-user/delete-label/" + id, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    });
    
    if (result.data.status) {
      yield put({ type: userActions.DELETE_LABEL_SUCCESS, payload: result.data });
    }
  } catch (error) {
    console.error("error userActiveInfoWorker: ", error.response.data)
    yield put({
      type: userActions.DELETE_LABEL_FAILED,
      payload: error.response.data
    });
  }
}

export const deleteLabelWatcher = function* () {
  yield takeLatest(userActions.DELETE_LABEL, deleteLabelWorker);
};


