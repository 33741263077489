import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2'
import { connect } from 'react-redux';
import userActions from '../../Store/Actions/userActions';
function UserActiveLabels({
    activeUser, activeUserLabels,
    storeLabel, sendDeletLabel,
    labelStatus, labelMessage,
    changeLabelStatus
}) {

    const [activeLabel, setActiveLabel] = useState({
        id: Math.random().toString(36).substr(2, 9),
        name: "",
        user_id: activeUser.id,
    });   
    const showAlert = () => {
        Swal.fire({
            icon: 'error',
            title: 'هشدار!',
            text: labelMessage,
            confirmButtonText: 'متوجه شدم!',
            onClose: () => {
                console.log("Close delete ");
                changeLabelStatus();
            }
        });
    }
    useEffect(() => {
        if (labelStatus) {
            showAlert()
        }
    }, [labelStatus]);

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            storeLabel(activeLabel);
            setActiveLabel((preState) => {
                return {
                    id: Math.random().toString(36).substr(2, 9),
                    user_id: activeUser.id,
                    name: ''
                };
            });
        }
    }
    const updateStateLabel = (e) => {
        let activeLabelUpdate = e.target.value;
        setActiveLabel((preState) => {
            return {
                ...preState,
                name: activeLabelUpdate
            };
        });
    }
    const deleteLable = (label) => {       
        sendDeletLabel(label);
    }


    return <>
        <div className="user-labels">
            {activeUserLabels.map((item, index) => {
                return <div key={"label" + index}>
                    <span className="label">{item.name}</span>
                    <span className="lable-delete icon-cross" onClick={() => deleteLable(item)}></span>
                </div>
            })}
        </div>
        <div className="label-form">
            <input type="text" value={activeLabel.name} className="form-control"
                onChange={updateStateLabel}
                onKeyDown={handleKeyDown} placeholder="برچسب جدید..." />
        </div>
    </>;
}



export default connect(state => ({
    activeUser: state.main.activeUser,
    activeUserLabels: state.main.activeUserLabels,
    labelStatus: state.main.labelStatus,
    labelMessage: state.main.labelMessage,
}), dispatch => ({
    storeLabel: label => {
        dispatch({ type: userActions.ACTIVE_USER_ADD_LABEL, payload: label });
    },
    sendDeletLabel: label => {
        dispatch({ type: userActions.DELETE_LABEL, payload: label });
    },
    changeLabelStatus: () => {
        dispatch({ type: userActions.CLOSE_ALERT_LABEL, payload: {} });
    }
}))(UserActiveLabels);