import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import authActions from '../Store/Actions/authActions';
import mainActions from '../Store/Actions/mainActions';


import Loader from './../Partials/Loader';

function Login({ doLogin, init, isInit, isUserLoggedIn, authStatus, authMessage }) {

    const token = localStorage.getItem('token');
    useEffect(() => {
        if (token && isInit) {
            init();
        }
    });
    if (token && isInit) {
        return <Loader />
    }

    const showMessage = <div>
        <div className="alert alert-danger">
            {authMessage}
        </div>
    </div>;
    return (
        <>
            {isUserLoggedIn && <Redirect to="/dashboard" />}
            <div className="sign">
                <div className="c-login">
                    {authStatus === false && showMessage}
                    <form onSubmit={e => {
                        e.preventDefault();
                        doLogin({
                            email: document.querySelector("#userEmail").value,
                            password: document.querySelector("#userPassword").value
                        })
                    }}>
                        <h2>ورود </h2>
                        <div className="form-group">
                            <label htmlFor="userEmail">ایمیل : </label>
                            <input type="email" className="form-control"
                                id="userEmail"
                                autoFocus required
                                placeholder="ایمیل" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="exampleInputPassword1">پسورد : </label>
                            <input type="password" id="userPassword" className="form-control" placeholder="پسورد" required />
                        </div>
                        <button type="submit" className="btn btn-primary">ورود</button>
                    </form>
                </div>
            </div>
        </>
    );
}


export default connect(
    state => {
        return {
            authStatus: state.main.authStatus,
            authMessage: state.main.authMessage,
            isUserLoggedIn: state.main.isUserLoggedIn,
            isInit: state.main.isInit,
        };
    },
    dispatch => {
        return {
            init: () => dispatch({ type: mainActions.INIT_REQUESTED, payload: {} }),
            doLogin: (payload) => dispatch({ type: authActions.USER_LOGIN, payload }),
        }
    }
)(Login);