import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import MessageActiveContext from '../../Contexts/MessageActiveId';
import userActions from '../../Store/Actions/userActions';
import { socket } from '../Container';
import Message from './Message';
import { Scrollbars } from 'react-custom-scrollbars';


let scrollFlagRequest = false;
let scrollToBottomFlag = false;
let activeId = null;
function Chat({
    activeUser, userActiveOnline, conversationHeight, maxWidth,
    userMessages, userMessagesFlag, messageNextOffset, hasMoreMessages,
    getMessageLoadMore
}) {

    const [messageActiveId, setMessageActiveId] = useState(0);
    const scrollbarsRef = React.createRef();
    const handleScroll = (data) => {
        if (scrollbarsRef.current.getScrollTop() < 10 && scrollFlagRequest === false && hasMoreMessages) {
            scrollFlagRequest = true;
            getMessageLoadMore({ init: false, user: activeUser, offset: messageNextOffset })
        }
    }
    const scrollToBottom = () => {
        scrollbarsRef.current.scrollToBottom();
    }
    useEffect(() => {
        console.log("useEffect Chat: ")
        if (scrollToBottomFlag === false && userMessages.length > 1 && userMessages.length < 15) {
            scrollToBottom()
            scrollToBottomFlag = true;
        }
        if (activeId !== activeUser.id && userMessages.length > 1) {
            activeId = activeUser.id;
            scrollToBottom()
        }

        return () => {
            scrollFlagRequest = false;
            scrollToBottomFlag = false;
        };
    }, [userMessagesFlag, userActiveOnline]);

    const setStatusActiveUser = (e) => {
        console.log("setStatusActiveUser : ", activeUser);
        // user_id activeUser.id
        socket.emit("admin:end:conversation", activeUser.id)
    }

    let headerClass = activeUser.user_state;
    let label = '';

    if (activeUser.user_state === 'open') {
        headerClass = 'bg-danger';
        label = 'در انتظار پاسخ ';
    }
    if (activeUser.user_state === 'wait') {
        headerClass = 'bg-info'
        label = 'در انتظار پاسخ ';
    }


    if (activeUser.user_state === 'close') {
        headerClass = 'bg-success';
        label = 'پاسخ داده شده ';
    }

    return (
        <div className="c-conversation " style={{ height: conversationHeight + 'px' }} >
            <header className={headerClass} onClick={setStatusActiveUser}>وضعیت <span className="d-inline-block px-2">{label}</span></header>
            <Scrollbars
                onScroll={handleScroll}
                renderTrackHorizontal={props => <div {...props} style={{ display: 'none' }} className="track-horizontal" />}
                renderView={props => {
                    props.style.marginBottom = -17;
                    props.style.marginRight = 0;
                    props.style.left = 3;
                    props.style.marginLeft = -17;
                    props.style.overflow = 'unset';
                    props.style.overflowY = 'scroll';
                    return (<div {...props} style={{ ...props.style }} />)
                }}
                ref={scrollbarsRef} style={{ width: maxWidth, height: conversationHeight, direction: 'rtl', paddingBottom: '.4rem' }}>
                <MessageActiveContext.Provider value={{ messageActiveId, setMessageActiveId }}>
                    <ul className="message-lists">
                        {
                            userMessages.map((message, index) => <Message message={message} key={index} />)
                        }
                    </ul>
                </MessageActiveContext.Provider>
            </Scrollbars></div>);
}

export default connect(state => ({
    activeUser: state.main.activeUser,
    userActiveOnline: state.main.userActiveOnline,
    userMessages: state.main.userMessages,
    userMessagesFlag: state.main.userMessagesFlag,
    messageNextOffset: state.main.messageNextOffset,
    messageLastId: state.main.messageLastId,
    hasMoreMessages: state.main.hasMoreMessages,
    messageSeen: state.main.messageSeen,
    conversationHeight: state.main.conversationHeight
}), dispatch => ({
    getMessageLoadMore: (payload) => {
        dispatch({ type: userActions.MESSAGE_LOAD_MORE_REQUEST, payload: payload });
    }
}))(Chat);



