import { findIndex } from "lodash";
import mainActions from "../Actions/mainActions";
import authActions from "../Actions/authActions";
import userActions from "../Actions/userActions";
import chatActions from "../Actions/chatActions";

const mainState = {
  authStatus: null,
  authCheck: true,
  authMessage: null,
  isInit: true,
  message: null,
  isUserLoggedIn: false,
  me: null,
  onlineUsers: [],

  currentChat: false,
  conversationHeight: window.innerHeight - 140,
  activeUser: null,
  userActiveOnline: false,
  activeUserInfo: null,
  userMessages: [],
  activeUserLabels: [],
  cycles: [],
  disease_categories: [],

  cycleInfo: null,
  labelStatus: false,
  labelMessage: "",

  // set user subscriptions active or inactive api info
  subscriptions:null,

  userMessagesFlag: false,
  hasMoreMessages: true,
  messageNextOffset: 0,
  messageLastId: null,
  messageSeen: false,
  admins: [],
  compose_message_reset: false,

  compose_parent_id: 0, // added for delete compose_message
  compose_parent: {}
};

const main = (state = mainState, action) => {
  let newState = state; 
  switch (action.type) {
    case mainActions.SET_CONVERSATION_HEIGHT:
      return {
        ...state,
        conversationHeight: action.height
      };
    case authActions.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isInit: false,
        isUserLoggedIn: true
      };
    case authActions.USER_LOGIN_FAILED:
      return {
        ...state,
        authStatus: action.payload.status,
        authMessage: action.payload.message
      };
    case authActions.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        isUserLoggedIn: false
      };
    case mainActions.INIT_SUCCESS:
      return {
        ...state,
        isInit: false,
        isUserLoggedIn: true,
        me: action.payload.me,
        admins: action.payload.admins
      };
    case mainActions.INIT_FAILED:
      newState = {
        ...state,
        isInit: false,
        isUserLoggedIn: false
      };
      break;

    case userActions.ACTIVE_USER_MESSAGES:
      let set_active_user = action.payload.user;
      if (set_active_user.user_state === "open") {
        set_active_user.user_state = "wait";
      }
      return {
        ...state,
        activeUser: set_active_user,
        hasMoreMessages: false,
        currentChat: true,
        userMessages: [],
        messageNextOffset: 0,
        messageLastId: null,
        messageSeen: false,
        subscriptions:null
      };
    case userActions.ACTIVE_USER_SUCCESS:
      return {
        ...state,
        userMessages: [...action.payload.messages, ...state.userMessages],
        messageNextOffset: action.payload.next_offset,
        hasMoreMessages: action.payload.has_more,
        messageLastId: action.payload.last_id,
        messageSeen: action.payload.seen
      };
    case userActions.ACTIVE_USER_INFO_SUCCESS:
      return {
        ...state,
        activeUserLabels: action.payload.labels,
        cycleInfo: action.payload.cycleInfo,
        cycles: action.payload.cycles,
        disease_categories: action.payload.disease_categories,
        activeUserInfo: action.payload.user,
        subscriptions: action.payload.subscriptions,
      };
    case userActions.ACTIVE_USER_LABEL_PUSH_SUCCESS:
      return {
        ...state,
        activeUserLabels: [...state.activeUserLabels, action.payload.label]
      };
    case userActions.DELETE_LABEL_SUCCESS:
      let deleteActiveUserLabels = state.activeUserLabels.filter(
        (label, index) => {
          return label.id !== action.payload.label.id;
        }
      );
      return {
        ...state,
        activeUserLabels: deleteActiveUserLabels,
        labelStatus: false,
        labelMessage: ""
      };
    case userActions.DELETE_LABEL_FAILED:
      return {
        ...state,
        labelStatus: !action.payload.status,
        labelMessage: action.payload.message
      };
    case userActions.CLOSE_ALERT_LABEL:
      return {
        ...state,
        labelStatus: false,
        labelMessage: ""
      };
    case chatActions.PUSH_MESSAGE_INIT:
      let userMessages = state.userMessages;
      userMessages.push(action.payload);      

      return {
        ...state,
        userMessages: userMessages,
        userMessagesFlag: !state.userMessagesFlag,
        compose_message_reset: !state.compose_message_reset,
        compose_parent_id: 0,
        compose_parent: {}
      };
    case chatActions.DELETE_PARENT_MESSAGE:
      return {
        ...state,
        compose_parent_id: 0,
        compose_parent: {}
      };
    case chatActions.PUSH_MESSAGE_SUCCESS:    
      return {
        ...state,
        userMessagesFlag: !state.userMessagesFlag
      };
    case chatActions.REPLACE_PUSH_MESSAGE:
      let updateMessages = state.userMessages;
      let messageIndex = findIndex(updateMessages, function(message) {
        return message.id === action.payload.temp_id;
      });
      if (messageIndex >= 0) {
        updateMessages[messageIndex] = action.payload;       
      }
      
      return {
        ...state,
        userMessagesFlag: !state.userMessagesFlag,
        userMessages: updateMessages
      };
    case chatActions.DELIVERED_MESSAGE:
      let deliveredMessages = state.userMessages;
      let deliverIndex = findIndex(deliveredMessages, function(message) {
        return message.id === action.payload.message_id;
      });
      if (deliverIndex >= 0) {
        deliveredMessages[deliverIndex].status = 1;
      }
      return {
        ...state,
        userMessagesFlag: !state.userMessagesFlag,
        userMessages: deliveredMessages
      };
    case chatActions.USER_SEEN_MESSAGE:
      let seenMessages = state.userMessages;
      let ids = action.payload.ids;
      for (let id of ids) {
        let seenIndex = findIndex(seenMessages, function(message) {
          return message.id === id;
        });
        if (seenIndex >= 0) {
          seenMessages[seenIndex].status = 2;
        }
      }
      return {
        ...state,
        userMessagesFlag: !state.userMessagesFlag,
        userMessages: seenMessages
      };
    case chatActions.PUSH_NEW_MESSAGE:
      let pushMessages = state.userMessages;
      action.payload.status = 2;
      pushMessages.push(action.payload);
      return {
        ...state,
        userMessagesFlag: !state.userMessagesFlag,
        userMessages: pushMessages
      };
    case chatActions.SET_COMPOSE_PARENT:
      return {
        ...state,
        compose_parent_id: action.payload.message.id,
        compose_parent: action.payload.message
      };
    case chatActions.ONLINE_ACTIVE_USER:
      let onlineActiveUser = state.activeUser;
      onlineActiveUser.online_status = "online";
      return {
        ...state,
        activeUser: onlineActiveUser,
        userActiveOnline: !state.userActiveOnline
      };
    case chatActions.OFFLINE_ACTIVE_USER:
      let offlineActiveUser = state.activeUser;
      offlineActiveUser.online_status = "offline";
      return {
        ...state,
        activeUser: offlineActiveUser,
        userActiveOnline: !state.userActiveOnline
      };
    case userActions.ACTIVE_USER_STATUS:
      let activeUserStatus = state.activeUser;
      if (activeUserStatus) {
        activeUserStatus.user_state = "open";
      }
      return {
        ...state,
        activeUser: activeUserStatus,
        userActiveOnline: !state.userActiveOnline
      };
    case chatActions.END_CONVERSATION_USER:     
      let ActiveUserStatus = state.activeUser;
      if (ActiveUserStatus && ActiveUserStatus.id == action.payload.user_id) {     
        ActiveUserStatus.user_state = 'close';
        ActiveUserStatus.message_status=2 ;
        ActiveUserStatus.sort=action.payload.sort ;
      }
      console.log("ActiveUserStatus : ",ActiveUserStatus)
      return {
        ...state,
        activeUser:ActiveUserStatus,
        userActiveOnline: !state.userActiveOnline
      };
    default:
      return state;
  }
  return newState;
};

export default main;
