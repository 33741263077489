import React from 'react';
import { getDateMoment } from '../../Utilitis/helper'

function UserActiveData({ user }) {
    return <div className="data">
        <div><span className="label">کد : </span>{user.id}</div>
        <div><span className="label"> نام : </span>{user.name}</div>
        <div>
                <span className="label"> تاریخ تولد : </span>
                <span className="date">{getDateMoment(user.birth_date)}</span>
        </div>
    </div>;
}


export default UserActiveData;