import { takeLatest, put, call } from "redux-saga/effects";
import { serialize } from "../../Utilitis/helper";
import userActions from "../Actions/userActions";
import HttpService from "../../Services/HttpService";

const httpService = new HttpService();

export const userWorker = function* (action) {
  try {
    const result = yield call(() => {
      const token = localStorage.getItem("token");
      return httpService.get("chat/users" + serialize(action.payload), {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    });
    yield put({ type: userActions.USER_LIST_SUCCESS, payload: result.data });
  } catch (error) {
    // console.error("error userWorker: ", error.response.data)
    yield put({
      type: userActions.USER_LIST_FAILED,
      payload: error.response.data
    });
  }
};

export const userWatcher = function* () {
  yield takeLatest(userActions.USER_LIST_REQUEST, userWorker);
};


export const searchUserWorker = function* (action) {
  try {
    console.log("action : ", action);
    const result = yield call(() => {
      const token = localStorage.getItem("token");
      return httpService.get("chat/users" + serialize(action.payload), {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    });
   
    yield put({ type: userActions.SEARCH_USER_SUCCESS, payload: result.data });
  } catch (error) {
    console.error("error userWorker: ", error.response.data)
    yield put({
      type: userActions.SEARCH_USER_FAILED,
      payload: error.response.data
    });
  }
}

export const searchUserWatcher = function* () {
  yield takeLatest(userActions.SEARCH_USER_REQUEST, searchUserWorker);
};




export const setTabUserWorker = function* (action) {
  try {
    console.log("action : ", action);
    const result = yield call(() => {
      const token = localStorage.getItem("token");
      return httpService.get("chat/users" + serialize(action.payload), {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
    });
    console.log("result.data : ", result.data);
    yield put({ type: userActions.USER_ACTIVE_TAB_SUCCESS, payload: result.data });
  } catch (error) {
    console.error("error setTabUserWorker: ", error.response.data)
    yield put({
      type: userActions.USER_ACTIVE_TAB_FAILED,
      payload: error.response.data
    });
  }
}


export const setTabUserWatcher = function* () {
  yield takeLatest(userActions.USER_ACTIVE_TAB_REQUEST, setTabUserWorker);
};

