import moment from 'moment-jalaali';
import { digitsEnToFa } from 'persian-tools';


export function setLink(text) {
    // var Rexp =/(\b(https?|http|www):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig;    
    var Rexp = /(\b(https?|http|www):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?/=~_|!:,.;]*)[-A-Z0-9+&@#/%=~_|])/ig;

    return text.replace(Rexp, "<a href='$1' target='_blank'>$3</a>");
}
export function serialize(obj) {
    return '?' + Object.keys(obj).reduce(function (a, k) {
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a
    }, []).join('&')
}

export function convertDateTime(created_at) {
    if (created_at) {       
        return digitsEnToFa(moment(created_at, 'YYYY-MM-DD HH:mm:ss').format('jYYYY/jM/jD HH:mm:ss'))
    }
    return created_at;
}
export function getDateMoment(date) {
    
    if (date) {
        return moment(date, 'YYYY-M-D HH:mm:ss').format('jYYYY/jMM/jDD');
    }
    return '';
}
export function getTimeMoment(date) {
    if (date) {
        return moment(date, 'YYYY-M-D HH:mm:ss').format('HH:mm:ss');
    }
    return '';
}