
export default {
    SEND_MESSAGE_INIT: "SEND_MESSAGE_INIT",
    SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
    SEND_MESSAGE_FAILED: "SEND_MESSAGE_FAILED",
    SAVE_MESSAGE_INIT: "USER_REGISTER",
    SAVE_MESSAGE_SUCCESS: "SAVE_MESSAGE_SUCCESS",
    SAVE_MESSAGE_FAILED: "SAVE_MESSAGE_FAILED",

    ONLINE_ACTIVE_USER: "ONLINE_ACTIVE_USER",
    OFFLINE_ACTIVE_USER: "OFFLINE_ACTIVE_USER",


    NEW_CHAT_INIT: "NEW_CHAT_INIT",
    NEW_CHAT_SUCCESS: "NEW_CHAT_SUCCESS",
    NEW_CHAT_FAILED: "NEW_CHAT_FAILED",

    FINISH_CHAT_INIT: 'FINISH_CHAT_INIT',

    ACTIVATE_RECENT_CHAT_INIT: "ACTIVATE_RECENT_CHAT_INIT",
    ACTIVATE_RECENT_CHAT_SUCCESS: "ACTIVATE_RECENT_CHAT_SUCCESS",
    ACTIVATE_RECENT_CHAT_FAILED: "ACTIVATE_RECENT_CHAT_FAILED",



    PUSH_MESSAGE_INIT: "PUSH_MESSAGE_INIT",
    PUSH_MESSAGE_SUCCESS: "PUSH_MESSAGE_SUCCESS",

    SET_MESSAGE_PARENT: "SET_MESSAGE_PARENT",
    DELETE_PARENT_MESSAGE: "DELETE_PARENT_MESSAGE",

    SET_COMPOSE_PARENT: "SET_COMPOSE_PARENT",

    REPLACE_PUSH_MESSAGE: "REPLACE_PUSH_MESSAGE",
    DELIVERED_MESSAGE: "DELIVERED_MESSAGE",
    USER_SEEN_MESSAGE: "USER_SEEN_MESSAGE",


    PUSH_NEW_MESSAGE: "PUSH_NEW_MESSAGE",


    END_CONVERSATION_USER:"END_CONVERSATION_USER"


};