import React, { useContext } from 'react';
import { connect } from 'react-redux';
import chatActions from './../../Store/Actions/chatActions';
import MessageActiveId from './../../Contexts/MessageActiveId';
import Avatar from './Avatar';
import Image from './Image';

import TextLog from './TextLog'
import MsgInfo from './MsgInfo';
import ParentMessage from './ParentMessage';
function Message({ message, setParentCompose }) {
    const { messageActiveId, setMessageActiveId } = useContext(MessageActiveId);

    const setActiveId = (id) => {
        if (messageActiveId === id) {
            setMessageActiveId(0);
        } else {
            setMessageActiveId(id);
        }
    }
    const setReplay = (e) => {
        setParentCompose(message);
        setMessageActiveId(0);
    }
    let menu_status = 'd-none';
    if (message.id && message.id === messageActiveId) {
        menu_status = 'inline-block';
    }
    const htmlParentMessage = message.parent_id ? <ParentMessage parent_id={message.parent_id} /> : null;

    const ImageView = message.type === "image" ? <Image content={message.content} /> : null;
    
    return <li className={message.sender}>
        <div>
            <div className="user-info">
                <div className="admin-info">
                    <Avatar type={message.sender} message={message}  />
                </div>
            </div>
            <div className="content">
                <div className="event-setting" >
                    <span className="icon-down-arrow" onClick={e => setActiveId(message.id)}></span>
                    <div className={menu_status + " setting-menu-list"}>
                        <a href=" #" onClick={setReplay} className="list-group-item list-group-item-action">پاسخ </a>
                        <a href=" #" className="list-group-item list-group-item-action">حذف </a>
                    </div>
                </div>
                <div className="msg-content">
                    {htmlParentMessage}
                    {ImageView}

                    <TextLog content={message.content} />
                </div>
                <MsgInfo message={message} />
            </div>
        </div>
    </li>
}

export default connect(state => ({
    userMessages: state.main.userMessages
}), dispatch => ({
    setParentCompose: (message) => {
        dispatch({ type: chatActions.SET_COMPOSE_PARENT, payload: { message: message } })
    }
}))(Message);