import React, { useState, useEffect } from 'react';
import moment from "moment-jalaali";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import HttpService from '../../Services/HttpService';
import mainActions from '../../Store/Actions/mainActions';
import chatActions from '../../Store/Actions/chatActions';

import TextareaAutosize from 'react-textarea-autosize';
import ComposePreviewImage from './ComposePreviewImage';
import ComposeParent from './ComposeParent';
import { socket } from '../Container';
const httpService = new HttpService();
let maxNumber = Math.floor((window.innerHeight / 3) / 50);
let windowHei = window.innerHeight;
const composeHeightDefault = 80;
const ImageBoxHeightDefault = 180;
const ParentMessageHeightDefault = 70;
const defaultPadding = 30;


// intial compose message 
const default_message = {
    id: Math.random()
        .toString(36)
        .substr(2, 9),
    user_id: 0, // how set user_id
    admin_id: 0, // how set  admin id
    sender: "admin", // admin, user
    status: 0, // 0=>send,1=>deliver,2=>seen
    type: "text", // default:text,voice ,image
    content: {
        url: "",
        text: "",
        size: 0
    },
    parent_id: 0,
    parent: {},
    created_at: moment().format("jYYYY/jM/jD HH:mm:ss")
};
function Compose({
    deleteParentMessageAction,
    sendMessage, updateConversationHeight,
    compose_parent_id,
    compose_parent,
    activeUser
}) {
    const inputFileUpload = React.createRef();
    const refComposeDetail = React.createRef();
    const composeContainer = React.createRef();


    const [composeSize, setComposeSize] = useState(ParentMessageHeightDefault);
    const [message, setMessageCompose] = useState(default_message);

    const [input_file, setFileCompose] = useState(null);
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        // console.log("######################## useEffect  start ", socket)
        let composeContainer = document.getElementById('composeContainer');
        if (composeContainer && message.parent_id === 0 && compose_parent_id !== 0) {
            let rect = composeContainer.getBoundingClientRect();
            let composeH = rect.height + ParentMessageHeightDefault;
            let conversationHeight = (windowHei - composeH) - 2;
            setComposeSize(composeH);
            updateConversationHeight(conversationHeight);
            // console.log("textAreaHeightChange mount composeH ", composeH)
        }
        if (compose_parent_id !== message.id) {            
            let change_message = message;
            change_message.parent_id = compose_parent_id;
            change_message.parent = compose_parent;
            setMessageCompose(change_message);
            
        }
        // console.log("message : ", message)
        // console.log("####################### useEffect  end ");
    }, [compose_parent_id, input_file]);//compose_parent_id, input_file

    const textAreaHeightChange = (e) => {
        // console.log(" textAreaHeightChange input ", Math.floor(e))
        let input = Math.floor(e);
        let composeH = 0;
        let conversationHeight = 0;
        if (input_file)
            composeH = (input + ImageBoxHeightDefault);
        else
            composeH = input + defaultPadding;

        if (message.parent_id) {
            composeH = composeH + ParentMessageHeightDefault;
        }
        conversationHeight = (windowHei - composeH) - 2;
        // console.log("textAreaHeightChange conversationHeight : ", conversationHeight)
        // console.log("textAreaHeightChange composeH : ", composeH);
        setComposeSize(composeH);
        updateConversationHeight(conversationHeight);
    }
    const setTextArea = (e) => {
        let textarea = e.target.value;
        setMessageCompose((prevMessage) => {
            return {
                ...prevMessage,
                content: { url: prevMessage.content.url, text: textarea, size: 0 }
            };
        });
    }
    const updateBoxHeight = (minusOrPlus = true) => {
        let composeH = 0;
        if (minusOrPlus) {
            composeH = refComposeDetail.current.clientHeight + ImageBoxHeightDefault;
        } else {
            composeH = Math.abs(refComposeDetail.current.clientHeight + defaultPadding);
        }
        if (message.parent_id) {
            composeH = composeH + ParentMessageHeightDefault;
        }
        let conversationHeight = (windowHei - composeH) - 2;
        setComposeSize(composeH);
        updateConversationHeight(conversationHeight);
    }
    const handleFileChange = (event) => {
        updateBoxHeight();
        setLoading(true);
        setFileCompose(URL.createObjectURL(event.target.files[0]));
        let formData = new FormData();
        formData.append("image_file", event.target.files[0]);
        const token = localStorage.getItem('token');
        httpService.post('chat/upload_file', formData, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
            setMessageCompose((prevMessage) => {
                return {
                    ...prevMessage,
                    content: { url: response.data.url, text: message.content.text, size: 0 },
                    type: "image"
                }
            });
            setLoading(false);
        }).catch((error) => {
            console.error(error.response, error.response.status);
            setLoading(false);
            if (error.response.status === 422) {
                console.log(error.response.data.errors.image_file[0])
                toast.error(error.response.data.errors.image_file[0], {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            } else {
                toast.error("کاربر گرامی آپلود با مشکل مواجه شده است.", {
                    position: toast.POSITION.BOTTOM_LEFT
                });
            }
        });
    }



    const selectFileClick = (e) => {
        inputFileUpload.current.click();
    }

    const saveMessageCompose = (e) => {

        if(activeUser.id===0){
            console.log("active user id empty ",activeUser);
            return ;
        }
        if (message.type === "text" && message.content.text.length === 0) {
            console.log("message.content.text return ")
            return;
        }

        if (message.type === "image" && message.content.text.length === 0 && message.content.url.length === 0) {
            console.log("message.content.url and text  return ")
            return;
        }

        message.user_id = activeUser.id;
        socket.emit("Admin:send:message", message);
        // text image


        sendMessage(message);
        setFileCompose(null);
        default_message.id = Math.random().toString(36).substr(2, 9);
        setMessageCompose(default_message)
        updateConversationHeight((windowHei - composeHeightDefault) - 2)
        setComposeSize(composeHeightDefault);

    }

    const deleteFile = (e) => {
        console.log(" message Delete   ", message, input_file);
        updateBoxHeight(false);
        setFileCompose(null);
        setMessageCompose((prevMessage) => {
            return { ...prevMessage, content: { url: "", text: message.content.text, size: 0 }, type: "text" }
        });
    }

    const deleteParentMessage = (e) => {
        console.log(" on click deleteParentMessage  start ", message);
        let update_message = message;
        update_message.parent_id = 0;
        update_message.parent = {};
        deleteParentMessageAction();
        setMessageCompose((prevMessage) => {
            return { ...prevMessage, parent_id: 0, parent: {} }
        });

        let composeContainer = document.getElementById('composeContainer');
        if (composeContainer) {
            let rect = composeContainer.getBoundingClientRect();
            let composeH = Math.abs(rect.height - ParentMessageHeightDefault);
            let conversationHeight = (windowHei - composeH) - 2;
            setComposeSize(composeH);
            updateConversationHeight(conversationHeight);
        }
    }

    const PreviewImage = input_file ? <ComposePreviewImage deleteFile={deleteFile} input_file={input_file} loading={loading} /> : null;

    const ParentMessage = compose_parent_id ? <ComposeParent deleteParentMessage={deleteParentMessage} message={compose_parent} /> : null;


    return (<div className="box-compose" id="composeContainer" ref={composeContainer} style={{ height: composeSize + 'px' }}>
        <div className="mt-auto">
            {ParentMessage}
            {PreviewImage}
            <div className="details" ref={refComposeDetail}>
                <TextareaAutosize onChange={setTextArea}
                    className="compose-textarea"
                    maxRows={maxNumber}
                    value={message.content.text}
                    onHeightChange={textAreaHeightChange}
                />
                <div className="compose-setting">
                    <span className="icon-clip" onClick={selectFileClick}></span>
                    <input type="file" ref={inputFileUpload}
                        onChange={(e) => handleFileChange(e)}
                        className="compose-file" />
                </div>
                <button disabled={loading} className="compose-confirm" onClick={saveMessageCompose}>تائید</button>
            </div>
        </div>
    </div>)
}

export default connect(state => ({
    compose_parent_id: state.main.compose_parent_id,
    compose_parent: state.main.compose_parent,
    activeUser: state.main.activeUser,
}), dispatch => {
    return {
        updateConversationHeight: (height) => {
            dispatch({ type: mainActions.SET_CONVERSATION_HEIGHT, height: height });
        },
        deleteParentMessageAction: () => {
            dispatch({ type: chatActions.DELETE_PARENT_MESSAGE, PAYLOAD: {} });
        },
        sendMessage: (message) => {
            dispatch({ type: chatActions.PUSH_MESSAGE_INIT, payload: message });
        }
    };
})(Compose);



