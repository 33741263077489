import { findIndex } from 'lodash'
import userActions from "../Actions/userActions";

const usersState = {
  status: null,
  message: null,
  init_request: false,
  users_flag: false,
  users: [],
  unread_message: 0,
  user_online_count: 0,
  tab: 'all' // all(default),unread,read 
};

const user = (state = usersState, action) => {
  let newState = state;
  try {
    switch (action.type) {
      case userActions.USER_LIST_REQUEST:
        newState = {
          ...state,
          init_request: true
        };
        break;
      case userActions.USER_LIST_SUCCESS:
        newState = {
          ...state,
          users: [...state.users, ...action.payload.users],
          next_offset: action.payload.next_offset
        };
        break;
      case userActions.USER_LIST_FAILED:
        newState = {
          ...state,
          status: action.payload.status,
          message: action.payload.message
        };
        break;
      case userActions.PUSH_NEW_USER:
        let push_users = state.users;
        push_users.unshift(action.payload);
        return {
          ...state,
          users: push_users,
          users_flag: !state.users_flag
        }
      case userActions.RECEIVED_NEW_MESSAGE:
        let updateCounterUsers = state.users;
        let userIndex = findIndex(updateCounterUsers, function (user) {
          return parseInt(user.id) === parseInt(action.payload.user_id)
        })
        if (userIndex >= 0) {
          updateCounterUsers[userIndex].content = action.payload.content;
          updateCounterUsers[userIndex].count_message = updateCounterUsers[userIndex].count_message + 1;
          updateCounterUsers[userIndex].sort = action.payload.sort;
          updateCounterUsers[userIndex].message_status = action.payload.message_status;
        }
        return {
          ...state,
          userMessages: updateCounterUsers,
          users_flag: !state.users_flag
        };

      case userActions.ACTIVE_USER_RESET_COUNTER:
        let resetCounterUsers = state.users;
        let resetIndex = findIndex(resetCounterUsers, function (user) {
          return parseInt(user.id) === parseInt(action.payload.userId)
        })

        if (resetIndex >= 0) {
          resetCounterUsers[resetIndex].count_message = 0;
          resetCounterUsers[resetIndex].message_status = 1; // read message
        }
        return {
          ...state,
          userMessages: resetCounterUsers,
          users_flag: !state.users_flag
        }
      case userActions.SEARCH_USER_REQUEST:
        console.log("userActions.SEARCH_USER_REQUEST reducer ")
        return {
          ...state,
          users: [],
          next_offset: 0,
          users_flag: !state.users_flag
        }
      case userActions.SEARCH_USER_SUCCESS:
        return {
          ...state,
          users: action.payload.users,
          users_flag: !state.users_flag,
          next_offset: action.payload.next_offset
        }
      case userActions.USER_ACTIVE_TAB_REQUEST:
        return {
          ...state,
          users: [],
          next_offset: 0,
          users_flag: !state.users_flag,
          tab: action.payload.tab
        };
      case userActions.USER_ACTIVE_TAB_SUCCESS:
        console.log("userActions.SEARCH_USER_SUCCESS :", action.payload)
        return {
          ...state,
          users: action.payload.users,
          users_flag: !state.users_flag,
          next_offset: action.payload.next_offset
        }
      case userActions.UPDATE_UNREAD_MESSAGE:
        return {
          ...state,
          unread_message: action.payload
        }
      case userActions.ONLINE_USER:
        let onlineUsers = state.users;
        let onlineIndex = findIndex(onlineUsers, function (user) {
          return parseInt(user.id) === parseInt(action.payload)
        })
        if (onlineIndex >= 0) {
          onlineUsers[onlineIndex].online_status = "online";
        }
        return {
          ...state,
          users: onlineUsers,
          users_flag: !state.users_flag,
        }
      case userActions.OFFLINE_USER:
        let offlineUsers = state.users;
        let offlineIndex = findIndex(offlineUsers, function (user) {
          return parseInt(user.id) === parseInt(action.payload)
        })
        if (offlineIndex >= 0) {
          offlineUsers[offlineIndex].online_status = "offline";
        }
        return {
          ...state,
          users: offlineUsers,
          users_flag: !state.users_flag,
        }
      case userActions.UPDATE_ONLINE_USER_COUNT:
        return {
          ...state,
          user_online_count: action.payload
        };
      case userActions.USER_STATUS:
        let users_status = state.users;
        let statusIndex = findIndex(users_status, function (user) {
          return parseInt(user.id) === parseInt(action.payload)
        })
        if (statusIndex >= 0) {
          users_status[statusIndex].user_state = "open";
        }
        return {
          ...state,
          users: users_status,
          users_flag: !state.users_flag,
        }
      case userActions.ACTIVE_USER_STATUS:
        return {
          ...state,
        }
      default:
        newState = state;
        break;
    }
  } catch (error) {
    console.error(error);
  }
  return newState;
};

export default user;
