import {takeLatest,put} from 'redux-saga/effects';//call
import chatActions from '../Actions/chatActions';
// import HttpService from '../../Services/HttpService';

// const httpService = new HttpService();


const messagesWorker = function* (action){
    try{
        // const result = yield call(()=>{
        //     const token= localStorage.getItem('token');       
        //     return httpService.post('/auth/init',action.payload,{
        //         headers:{
        //             'Authorization': `Bearer ${token}`,   
        //             // 'Authorization': `Bearer fsdfsdfsdfsd`,                    
        //         }
        //     });
        //     // .then(response=>response.body).catch(error=>{});
        // });
        // console.log("initWorker result ",result)
        yield put({type:chatActions.PUSH_MESSAGE_SUCCESS,payload:action}) ;       
        // registerUser
    }catch(error){
        console.error("initWorker result ",error)
        // yield put({type:mainActions.INIT_FAILED,payload:error.response.data}); 
    }
};

export const messagesWatcher = function* (){
    yield takeLatest(chatActions.PUSH_MESSAGE_INIT,messagesWorker)
};
