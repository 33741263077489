
export default {

    USER_LIST_REQUEST: "USER_LIST_REQUEST",
    USER_LIST_SUCCESS: "USER_LIST_SUCCESS",
    USER_LIST_FAILED: "USER_LIST_FAILED",

    ONLINE_USER: "ONLINE_USER",
    OFFLINE_USER: "OFFLINE_USER",

    USER_STATUS: "USER_STATUS",
    ACTIVE_USER_STATUS: "ACTIVE_USER_STATUS",

    UPDATE_ONLINE_USER_COUNT: "UPDATE_ONLINE_USER_COUNT",

    UPDATE_UNREAD_MESSAGE: "UPDATE_UNREAD_MESSAGE",

    USER_ACTIVE_TAB_REQUEST: "USER_ACTIVE_TAB_REQUEST",
    USER_ACTIVE_TAB_SUCCESS: "USER_ACTIVE_TAB_SUCCESS",
    USER_ACTIVE_TAB_FAILED: "USER_ACTIVE_TAB_FAILED",


    ACTIVE_USER_MESSAGES: "ACTIVE_USER_MESSAGES",
    ACTIVE_USER_SUCCESS: "ACTIVE_USER_SUCCESS",
    ACTIVE_USER_FAILED: "ACTIVE_USER_FAILED",

    SEARCH_USER_REQUEST: "SEARCH_USER_REQUEST",
    SEARCH_USER_FAILED: "SEARCH_USER_FAILED",
    SEARCH_USER_SUCCESS: "SEARCH_USER_SUCCESS",


    MESSAGE_LOAD_MORE_REQUEST: "MESSAGE_LOAD_MORE_REQUEST",
    MESSAGE_LOAD_MORE_SUCCESS: "MESSAGE_LOAD_MORE_SUCCESS",
    MESSAGE_LOAD_MORE_FAILED: "MESSAGE_LOAD_MORE_FAILED",

    ACTIVE_USER_INFO_REQUEST: "ACTIVE_USER_INFO_REQUEST",

    ACTIVE_USER_INFO_SUCCESS: "ACTIVE_USER_INFO_SUCCESS",
    ACTIVE_USER_INFO_FAILED: "ACTIVE_USER_INFO_FAILED",

    ACTIVE_USER_ADD_LABEL: "ACTIVE_USER_ADD_LABEL",
    ACTIVE_USER_LABEL_PUSH_SUCCESS: "ACTIVE_USER_LABEL_PUSH_SUCCESS",
    ACTIVE_USER_LABEL_PUSH_FAILED: "ACTIVE_USER_LABEL_PUSH_FAILED",

    DELETE_LABEL: "DELETE_LABEL",
    DELETE_LABEL_SUCCESS: "DELETE_LABEL_SUCCESS",
    DELETE_LABEL_FAILED: "DELETE_LABEL_FAILED",
    CLOSE_ALERT_LABEL: "CLOSE_ALERT_LABEL",

    RECEIVED_NEW_MESSAGE: "RECEIVED_NEW_MESSAGE",
    PUSH_NEW_USER: "PUSH_NEW_USER",

    ACTIVE_USER_RESET_COUNTER: "ACTIVE_USER_RESET_COUNTER"



};