import React from 'react';

function ComposeParent({ message, deleteParentMessage }) {
    
    const ImageView = message.type === "image" ? <div className="composer-image"><img alt="" src={message.content.url} /></div> : null;

    const TextLog = message.content.text ? <div className="txt">{message.content.text.substring(0, 30)}</div> : null;

    return <div className="com-parent-message mb-1">
        <div className="info">
            {TextLog}
            {ImageView}
        </div>
        <div className="close-box">
            <span className="icon-cross" onClick={deleteParentMessage}></span>
        </div>
    </div>
}


export default ComposeParent;