import React,{useState} from 'react';
import { connect } from 'react-redux';
import ScrollArea from 'react-scrollbar';
import userActions from './../../Store/Actions/userActions';
import Header from './Header';
import UsersLists from './UsersLists';

let scrollbarStyles = { borderRadius: 5 };
function Sidebar({getSearchUser}) {
    
    const [input_search, setInputSearch] = useState('');
    const ScrollAreaRef = React.createRef();

    const searchUser = (e)=>{       
        getSearchUser(input_search);
    }
    
    const handleScroll = (data)=>{
        // console.log("data : ",data)
    }
    return (<div className="sidebar">
        <Header /> 
        <ScrollArea onScroll={(data) => handleScroll(data)}
                    className={"user-list-container"}
                    style={{ height: window.innerHeight }}
                    vertical={true}
                    ref={ScrollAreaRef}
                    smoothScrolling={true}
                    verticalContainerStyle={scrollbarStyles}
                    verticalScrollbarStyle={scrollbarStyles}>
                    <div className="user-search">
                        <input type="text" className="form-control" placeholder="جستجوی ... " 
                            name="in_user_search" value={input_search}
                            onChange={(e)=>setInputSearch(e.target.value)}
                        />
                        <button className="btn-search btn-sm" onClick={searchUser}>جستجو </button>
                    </div>        
            <UsersLists />
        </ScrollArea> 
    </div>)
}


export default connect(state => ({}), dispatch => ({
    getSearchUser: userId =>  dispatch({ type: userActions.SEARCH_USER_REQUEST, payload: { userId:userId } }),
}))(Sidebar);