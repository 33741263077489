import React from 'react';
import { renderToString } from 'react-dom/server';
import { connect } from 'react-redux';

import HttpService from './../../Services/HttpService';
import Swal from 'sweetalert2';
import ViewDiseaseCategory from './ViewDiseaseCategory';
const httpService = new HttpService();

function DiseaseCategories({ disease_categories }) {

    const loadDiseaseCategory = (question_answer) => {
        const token = localStorage.getItem('token');
        const { id, disease_category_id } = question_answer;
        httpService.get(`active-user/${id}/${disease_category_id}/get-user-question-answers`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then((response) => {
            console.log("Response : ", response);
            const { question_answers } = response.data;
            Swal.fire({
                title: question_answer.title,
                html: renderToString(<ViewDiseaseCategory result={question_answer} question_answers={question_answers} />),
                customClass: 'swal-wide question-answers mt-3',
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'بستن',
            });
        }).catch((error) => {
            console.error("Error : ", error)
        });
    }

    return <div>
        <ul className=" aside-disease-category">
            {
                disease_categories.map((question_answer, index) => {
                    let answer = question_answer.answer ? 'icon-tick text-success px-2' : 'icon-cross px-2 text-danger';
                    return <li className="list-group-item p-1 d-flex justify-content-between"
                        key={"disease_category" + question_answer.id}
                        onClick={() => loadDiseaseCategory(question_answer)}>
                        <div>
                            <span className={answer}></span>
                            {question_answer.title}
                        </div>                        
                    </li>
                })
            }
        </ul>
    </div>
}



export default connect(state => ({
    disease_categories: state.main.disease_categories,

}), dispatch => ({

}))(DiseaseCategories);