
import { takeLatest, put } from 'redux-saga/effects';
import socketActions from '../Actions/socketActions';

export const socketConnectWorker = function* (action) {
    try {       
        yield put({ type: socketActions.SOCKET_CONNECT_SUCCESS, payload: action.payload });
    } catch (error) {
        yield put({ type: socketActions.SOCKET_CONNECT_FAILED, payload: action });
    }
}


export const socketConnectWatcher = function* () {
    yield takeLatest(socketActions.SOCKET_CONNECT, socketConnectWorker)
}


export const socketDisConnectWorker = function* (action) {
    try {
        
        yield put({ type: socketActions.SOCKET_DISCONNECT_SUCCESS, payload: action });
    } catch (error) {
        yield put({ type: socketActions.SOCKET_DISCONNECT_FAILED, payload: action });
    }
}


export const socketDisConnectWatcher = function* () {
    yield takeLatest(socketActions.SOCKET_DISCONNECT, socketDisConnectWorker)
}