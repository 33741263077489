import socketActions from '../Actions/socketActions';


const socketState = {
    socketStatus: null,// true connected,false disconnected, null not initial     
    socketMessage: null,
    socketConnection: null
};

const socket = (state = socketState, action) => {
    let newState = state;
    
    switch (action.type) {
        case socketActions.SOCKET_CONNECT_SUCCESS:           
            newState = {
                ...state,
                socketConnection: action.payload.socket,
                socketStatus: true,
                socketMessage: null,
            }
            break;
        case socketActions.SOCKET_CONNECT_FAILED:            
            newState = {
                ...state,
                socketConnection: null,
                socketStatus: false,
                socketMessage: "کاربر گرامی ارتباط شما با  سرور برقرار نشده است(socket connection failed.)"
            }
            break;
        case socketActions.SOCKET_DISCONNECT_SUCCESS:           
            newState = {
                ...state,
                socketConnection: null,
                socketStatus: false,
                socketMessage: null,
            }
            break;
        default:
            newState = state;
            break;
    }

    return newState;
}

export default socket;