import React from "react";
import routes from './Router/routes';
import { Route, Switch } from 'react-router-dom';

import './app.scss';
function App() {
  return (
    <>
      <Switch>
        {routes.map((route, index) => <Route key={index} {...route}></Route>)}
      </Switch>
    </>
  );
}


export default App;
