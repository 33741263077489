
export default {
    USER_LOGIN:"USER_LOGIN",
    USER_LOGIN_SUCCESS:"USER_LOGIN_SUCCESS",
    USER_LOGIN_FAILED:"USER_LOGIN_FAILED",
    USER_REGISTER:"USER_REGISTER",
    USER_REGISTER_SUCCESS:"USER_REGISTER_SUCCESS",
    USER_REGISTER_FAILED:"USER_REGISTER_FAILED",

    USER_LOGOUT:"USER_LOGOUT",
    USER_LOGOUT_SUCCESS:"USER_LOGOUT_SUCCESS",
    USER_LOGOUT_FAILED:"USER_LOGOUT_FAILED",

    AUTH_CHECK:"AUTH_CHECK",
    AUTH_CHECK_SUCCESS:"AUTH_CHECK_SUCCESS",
    AUTH_CHECK_FAILED:"AUTH_CHECK_FAILED",

};