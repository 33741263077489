import Login from '../Auth/Login';
import Main from '../Main/Main';
import NotFound from '../Partials/NotFound';
export default [
    {        
        path:"/auth/login",
        component:Login
    },   
    {
        path:"/dashboard",
        component:Main
    },
    {
        path:"/",
        component:Main
    },
    {     
        component:NotFound
    }
]