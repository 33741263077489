import React  from 'react';
import Swal from 'sweetalert2'

function Image({ content }) {
   
    const previewImage = (image_url) => {       
        Swal.fire({            
            html: `<img src='${image_url}' class="w-100"/>`,            
            customClass: 'swal-wide',
            confirmButtonColor: '#dc3545',
            confirmButtonText: 'بستن'
        });           
    }
    return <img alt=" " className="chat-image" src={content.url}
        onClick={(e) => previewImage(content.url)}
        onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/profile/images/avatar.jpg";
        }}
    />;
}


export default Image;