import React from 'react';


function ComposePreviewImage({ loading,deleteFile, input_file }) {

    return <div className="com-box-file">
        {loading ? <div className="loading">در حال آپلود فایل ....</div> : null}
        <div className="box">
            <img className="image-preview" src={input_file} alt="preview" />
            <span className="compose-close icon-cross" onClick={deleteFile}></span>
        </div>
    </div>
}

export default ComposePreviewImage;